import React, { useState } from "react";
import PropTypes from "prop-types";
import { Flex } from "rebass";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import Link from "../../Link";
import BurgerMenu from "./BurgerMenu";
import { ModalGlobalStyles } from "../../../utils/theme/globalStyles";

const MenuMobile = ({ menuLinks }) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <Flex
      css={`
        display: none !important;
        @media (max-width: 1279px) {
          display: flex !important;
        }
      `}
    >
      <BurgerMenu onClick={() => setIsOpen(!isOpen)} isHidden={isOpen} />
      {!isOpen ? (
        <>
          <ModalGlobalStyles modalIsOpen={!isOpen} />
          <Flex
            width="100%"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            css={`
              position: fixed;
              height: 100vh;
              background-color: rgba(28, 20, 48, 1);
              opacity: ${!isOpen ? 1 : 0};
              transition: opacity 0.4s;
              z-index: 3;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              @media (max-width: 1023px) and (orientation: landscape) {
                position: absolute;
                min-height: 100vh;
              }
            `}
          >
            <Flex as="nav" flexDirection="column">
              {menuLinks.map(({ name, link, event }) => (
                <Link
                  onClick={() => {
                    // eslint-disable-next-line no-unused-expressions
                    link[0] === "#" ? setIsOpen(true) : null;
                    trackCustomEvent({
                      category: "Click",
                      action: event,
                      label: "HeaderMenu"
                    });
                  }}
                  key={name}
                  to={link}
                  fontSize={1}
                  mb={5}
                  animation
                  css={{ display: "flex", justifyContent: "center" }}
                >
                  {name}
                </Link>
              ))}
            </Flex>
          </Flex>
        </>
      ) : null}
    </Flex>
  );
};

MenuMobile.propTypes = {
  menuLinks: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default MenuMobile;
