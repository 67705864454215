import * as React from "react";
import PropTypes from "prop-types";
import { Box } from "rebass";
import BurgerLine from "./BurgerLine";

const BurgerMenu = ({ isHidden, ...props }) => (
  <Box
    {...props}
    css={{ position: "relative", width: "30px", height: "30px", zIndex: 10 }}
  >
    <BurgerLine
      bg="white"
      width="31px"
      top={`${isHidden ? 10 : 0}px`}
      left={`${isHidden ? 0 : 12}px`}
      transform={`rotate(${isHidden ? 0 : 227}deg) translate(${
        isHidden ? 0 : -29
      }px, ${isHidden ? -1 : -9}px)`}
      transition="all 0.2s ease-in"
    />
    <BurgerLine
      bg="white"
      width={isHidden ? "16px" : "31px"}
      transform={`rotate(${isHidden ? 0 : 134}deg) translate(${
        isHidden ? 0 : -14
      }px, ${isHidden ? -1 : -7}px)`}
      top={`${isHidden ? 20 : 10}px`}
      left={`${isHidden ? 0 : 12}px`}
      transition="all 0.2s ease-in"
    />
  </Box>
);

BurgerMenu.propTypes = {
  isHidden: PropTypes.bool.isRequired
};

export default BurgerMenu;
