/* eslint-disable jsx-a11y/html-has-lang */
import * as React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";

const SEO = ({
  title,
  titleTemplate,
  keywords,
  description,
  image,
  url,
  pageType,
  lang
}) => {
  const itemListElement = [
    {
      "@type": "ListItem",
      item: {
        "@id": url,
        name: "Homepage"
      },
      position: 1
    }
  ];
  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    description: "Breadcrumbs list",
    name: "Breadcrumbs",
    itemListElement
  };
  return (
    <Helmet
      title={title}
      titleTemplate={titleTemplate}
      htmlAttributes={{
        lang,
        prefix:
          "og:http://ogp.me/ns# website:http://ogp.me/ns/website# article:http://ogp.me/ns/article# profile:http://ogp.me/ns/profile# fb:http://ogp.me/ns/fb#"
      }}
    >
      {description && <meta name="description" content={description} />}
      {description && <meta name="twitter:description" content={description} />}
      {description && <meta name="twitter:image:alt" content={description} />}

      {description && <meta name="keywords" content={keywords} />}

      {image && <meta name="image" content={image} />}
      {image && <meta name="twitter:image:src" content={image} />}
      {image && <meta name="twitter:image" content={image} />}

      <meta
        name="twitter:card"
        content={image ? "summary_large_image" : "summary"}
      />
      <meta name="twitter:title" content={title} />

      <meta property="og:url" content={url} />
      <meta property="og:type" content={pageType} />
      <meta property="og:title" content={title} />
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}
      {description && <meta property="og:image:alt" content={description} />}
      <meta property="og:locale" content="en_US" />
      <meta property="og:site_name" content="DashMedia" />
      <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      <link rel="canonical" href={url} />
    </Helmet>
  );
};
export default SEO;

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  titleTemplate: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string.isRequired,
  keywords: PropTypes.string.isRequired,
  pageType: PropTypes.string,
  lang: PropTypes.string
};

SEO.defaultProps = {
  description: null,
  image: null,
  titleTemplate: null,
  pageType: "website",
  lang: "en"
};
