import React from "react";
import PropTypes from "prop-types";
import { Flex } from "rebass";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import Link from "../../Link";

const MenuDesktop = ({ menuLinks }) => (
  <Flex
    css={`
      @media (max-width: 1279px) {
        display: none !important;
      }
    `}
  >
    {menuLinks.map(({ name, link, event }) => (
      <Link
        key={name}
        to={link}
        fontSize={1}
        mr={7}
        animation
        css={`
          :last-child {
            margin-right: 0px;
          }
        `}
        onClick={() => {
          trackCustomEvent({
            category: "Click",
            action: event,
            label: "HeaderMenu"
          });
        }}
      >
        {name}
      </Link>
    ))}
  </Flex>
);

MenuDesktop.propTypes = {
  menuLinks: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default MenuDesktop;
