import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Box, Flex } from "rebass";
import LogoIcon from "../../assets/icons/logo.svg";
import MenuDesktop from "./MenuDesktop";
import MenuMobile from "./MenuMobile";
import Link from "../Link";

const Navigation = () => {
  const {
    site: {
      siteMetadata: { menuLinks }
    }
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          menuLinks {
            name
            link
            event
          }
        }
      }
    }
  `);
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Box
        css={`
          @media (max-width: 767px) {
            height: 18px;
            width: 125px;
          }
        `}
      >
        <Link to="/">
          <LogoIcon style={{ width: "100%", height: "100%" }} />
        </Link>
      </Box>
      <MenuDesktop menuLinks={menuLinks} />
      <MenuMobile menuLinks={menuLinks} />
    </Flex>
  );
};

export default Navigation;
